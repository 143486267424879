import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/NewHome.vue')
  },
  // {
  //   path: '/solicitud',
  //   name: 'Solicitud',
  //   component: () => import(/* webpackChunkName: "solicitud" */ '@/views/Solicitud.vue')
  // },
  {
    path: '/descarga',
    name: 'Descarga',
    component: () => import(/* webpackChunkName: "ayuda" */ '@/views/Descarga.vue')
  },
  {
    path: '/preguntas-frecuentes',
    name: 'PreguntasFrecuntes',
    component: () => import(/* webpackChunkName: "ayuda" */ '@/views/Politicas.vue')
  },
  {
    path: '/campaign/:slugCampaign',
    name: 'Campaign',
    component: () => import(/* webpackChunkName: "Campaign" */ '@/views/Campaign.vue'),
  },
  {
    path: '/pqrs',
    name: 'pqrsquac',
    component: () => import(/* webpackChunkName: "pqrsquac" */ '@/views/PQRS.vue'),
  },
  {
    path: '/pqrs/consulta',
    name: 'consultpqrsquac',
    component: () => import(/* webpackChunkName: "consultpqrsquac" */ '@/views/ConsultPQRS.vue'),
  },
  {
    path: '/legales/',
    name: 'legalesPage',
    component: () => import(/* webpackChunkName: "legalesPage" */ '@/views/Politicas.vue'),
    redirect: { name: 'Home' },
    children: [
      {
        path: 'tyc',
        name: 'Tyc',
        component: () => import(/* webpackChunkName: "legalesPage" */ '@/views/Politicas.vue')
      },
      {
        path: 'politica-proteccion-datos-personales',
        name: 'PoliticaTratamiento',
        component: () => import(/* webpackChunkName: "legalesPage" */ '@/views/Politicas.vue')
      },
      {
        path: 'autorizacion-de-tratamiento-de-datos-y-centrales-riesgos',
        name: 'AuthTratamiento',
        component: () => import(/* webpackChunkName: "legalesPage" */ '@/views/Politicas.vue')
      },
      {
        path: 'politica-credito',
        name: 'PoliticasCredito',
        component: () => import(/* webpackChunkName: "legalesPage" */ '@/views/Politicas.vue')
      },
      {
        path: 'aviso-de-privacidad',
        name: 'AvisoDePrivacidad',
        component: () => import(/* webpackChunkName: "legalesPage" */ '@/views/Politicas.vue')
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Home' },
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
          })
        }, 100)
      })
    }
  }
})

export default router
